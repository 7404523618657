<template lang="pug">
section.slider(:class='{ _sold: sold, _reverse: reverse, _first: index == 2, _an: animation }')
  .slider-bg(:style='{ gridRow: index }')
  .slider-cont(:style='{ gridRow: index }')
    .slider-image(v-if='topListings')
      //- img(v-for="(listing, i) in topListings" :src="$hostname + listing.image.url" v-show="i == currentSlide")
      .sliderAnimation(v-for='(listing, i) in topListings', :class='checkCurrentClass(i)'): img.sliderAnimation-img.curp(
        alt,
        :src='$hostname + imgUrl(listing.image)',
        @click='$store.getters.desktop ? $router.push({ name: "SinglePage", params: { id: listing.link } }) : changeLocation(listing.link)'
      )
      .slider-pagination
        .slider-action._left(@click='changeSlide(-1)'): SvgComponent(name='arrow2')
        .slider-current {{ currentSlide + 1 }} / {{ topListings.length }}
        .slider-action._right(@click='changeSlide(1)'): SvgComponent(name='arrow2')
    .slider-image(v-if='listing')
      .sliderAnimation(
        v-for='(image, i) in listing.Content.SliderImages',
        :class='checkCurrentClass(i)'
      ): img.sliderAnimation-img.curp(
        alt,
        :src='$hostname + imgUrl(image)',
        @click='$store.getters.desktop ? $router.push({ name: "SinglePage", params: { id: listing.link } }) : changeLocation(listing.link)'
      )
      .slider-pagination
        .slider-action._left(@click='changeSlide(-1)'): SvgComponent(name='arrow2')
        .slider-current {{ currentSlide + 1 }} / {{ listing.Content.SliderImages.length }}
        .slider-action._right(@click='changeSlide(1)'): SvgComponent(name='arrow2')
    .slider-text(v-if='topListings || sold')
      h2.slider-title(v-if='!sold') Agency Listings <br>of the day
      h2.slider-title(v-else-if='index == 2') Agency <br>Sold Listings
      router-link.slider-name(
        :is='$store.getters.desktop ? "router-link" : "a"',
        :to='{ name: "SinglePage", params: { id: sold ? listing.link : topListings[currentSlide].link } }',
        :href='`${$frontname}listing/${sold ? listing.link : topListings[currentSlide].link}`'
      ) {{ sold ? listing.title : topListings[currentSlide].title }}
      router-link.bounce.slider-arrowLink(
        :is='$store.getters.desktop ? "router-link" : "a"',
        :to='{ name: "SinglePage", params: { id: sold ? listing.link : topListings[currentSlide].link } }',
        :href='`${$frontname}listing/${sold ? listing.link : topListings[currentSlide].link}`'
      ): SvgComponent.slider-arrow(
        name='arrow'
      )
      .slider-location {{sold ? listing.address : topListings[currentSlide].address}}
      .slider-price {{ (sold ? listing.priceValue : topListings[currentSlide].priceValue) | toCurrency }}
      .slider-params
        .slider-param(v-if='(topListings&&topListings[currentSlide].beds)||sold') {{ sold ? listing.beds : topListings[currentSlide].beds }} BR
        .slider-param(v-if='(topListings&&topListings[currentSlide].baths)||sold') {{ sold ? listing.baths : topListings[currentSlide].baths }} BA
        .slider-param(v-if='(topListings&&topListings[currentSlide].sqft)||sold') {{ sold ? listing.sqft : topListings[currentSlide].sqft }} SQFT
        //- .slider-param 3 ACRE
      router-link.listing.button(
        :is='$store.getters.desktop ? "router-link" : "a"',
        v-if='!sold',
        :to='{ name: "SinglePage", params: { id: topListings[currentSlide].link } }',
        :href='`${$frontname}listing/${sold ? listing.id : topListings[currentSlide].link}`'
      ) learn more
      img.slider-subimage(
        alt,
        v-else,
        :src='$hostname + listing.Content.SliderImages[nextSlide].formats.small.url'
      )
</template>

<script>
  import SvgComponent from '@/components/SvgComponent.vue';

  export default {
    // namedslots in template?
    data() {
      return {
        topListings: null,
        currentSlide: 0,
        nextSlide: 1,
        animation: false,
      };
    },
    components: {
      SvgComponent,
    },
    methods: {
      imgUrl(image) {
        return image.formats.medium?.url || image.url;
        // return this.$hostname + this.listing.image.formats.medium?.url
      },
      checkCurrentClass: function (i) {
        const lengthArray = this.sold
          ? this.listing.Content.SliderImages.length
          : this.topListings.length;
        var nextCurrent = this.currentSlide < lengthArray - 1 ? this.currentSlide + 1 : 0,
          prevCurrent = this.currentSlide > 0 ? this.currentSlide - 1 : lengthArray - 1;
        setTimeout(() => {
          this.nextSlide = nextCurrent;
        }, 0);

        if (i == this.currentSlide) {
          return '_current';
        }
        if (i == nextCurrent) {
          return '_prev_slide';
        }
        if (i == prevCurrent) {
          return '';
        }
      },
      changeLocation(link) {
        console.log(link);
        window.location.href = `${this.$frontname}listing/${link}`;
      },
      changeSlide: function (param) {
        if (!this.sold) {
          this.animation = true;
          setTimeout(() => {
            this.animation = false;
          }, 100);
        }

        const lengthArray = this.sold
          ? this.listing.Content.SliderImages.length
          : this.topListings.length;
        console.log('lengthArray', lengthArray);
        if (this.currentSlide + param == -1) {
          this.currentSlide = lengthArray - 1;
        } else if (this.currentSlide + param >= lengthArray) {
          this.currentSlide = 0;
        } else {
          this.currentSlide += param;
        }
      },
      preloadImage: function (item) {
        let img = new Image();

        img.onload = () => {
          // console.log('img preloaded', img.src);
        };
        // console.log(this.$hostname + (item.image.formats.medium?.url || item.image.url));
        img.src = this.$hostname + (item.image.formats.medium?.url || item.image.url);
      },
    },
    props: {
      sold: Boolean,
      reverse: Number,
      index: Number,
      listing: Object,
      // listingArray: Array
    },
    // computed: {
    //   listings() {
    //     if (!this.sold) {
    //       return this.listingArray;
    //     }
    //     else return this.listingArray
    //   }
    // },
    async mounted() {
      if (!this.sold) {
        this.topListings = await this.$store.dispatch('getTopListings');
        this.topListings.forEach((item) => {
          this.preloadImage(item);
        });
      }
      console.log(123,this.topListings)
    },
  };
</script>

<style lang="scss">
  .slider {
    user-select: none;

    display: contents;
    position: relative;

    & {
      .slider-name,
      .bounce,
      .slider-location,
      .slider-price,
      .slider-params {
        opacity: 0;
        transform: translateX(100px);
      }
    }
    &:not(._an) {
      .slider-name,
      .bounce,
      .slider-location,
      .slider-price,
      .slider-params {
        opacity: 1;
        transform: none;
        transition: 0.25s;
      }
      //       .slider-name{
      // transition-delay: .05s;
      //       }
      //       .bounce{
      // transition-delay: .1s;
      //       }
      //       .slider-location{
      // transition-delay: .15s;
      //       }
      //       .slider-price{
      // transition-delay: .2s;
      //       }
      //       .slider-params{
      // transition-delay: .25s;
      //       }
    }
    &-current {
      width: 50px;
      text-align: center;
      @include phone {
        font-size: 24px;
      }
    }
    &-bg {
      background: black;
    }
    &-cont {
      display: flex;
      color: white;
      // align-items: flex-end;
      padding-bottom: 120px;
      @include middle {
        padding-bottom: 80px;
      }
      @include phone {
        flex-direction: column;
        align-items: flex-start;
        padding-bottom: 28px;
        position: relative;
      }
    }
    &-text {
      display: flex;
      flex-direction: column;
      @include gap(10px, col);
      padding-top: 40px;
      margin-left: 50px;
      @include middle {
        margin-left: 20px;
      }
      @include onlytablet {
        margin-left: 50px;
        width: 190px;
      }
      @include nophone {
        // width: 40%;
        flex-grow: 1;
      }
      @include phone {
        margin-left: 0;
        width: 100%;
        padding-top: 0;
      }
    }
    &-arrow {
      transform: scaleX(-1);
      cursor: pointer;
      user-select: none;
      @include phone {
        display: none;
      }
    }
    &-arrowLink {
      align-self: flex-start;
    }
    &-pagination {
      position: absolute;
      top: -70px;
      right: 0;
      display: flex;
      color: black;
      @include gap(70px, row);
      z-index: 1;
      @include phone {
        left: 50%;
        transform: translateX(-50%);
        right: auto;
      }
    }
    &-action {
      color: #c4c4c4;
      cursor: pointer;
      transition: 0.2s color;
      position: relative;
      &:before {
        content: '';
        top: -10px;
        left: -20px;
        right: -20px;
        bottom: -10px;
        position: absolute;
      }
      &:hover {
        color: $red;
      }
      &:active {
        color: black;
      }
      &._right {
        transform: scaleX(-1);
      }
    }

    &-image {
      width: 70%;

      position: relative;
      // flex-grow: 1;
      flex-shrink: 0;
      @include middle {
        width: 708px;
      }
      @include small {
        align-self: flex-start;
        width: 60%;
      }

      img {
        width: 100%;
        // height: 650px;
        object-fit: cover;
        position: relative;
        z-index: 1;
        margin-top: -30px;
        // top: -30px;
        // top: 0;
        @include middle {
          height: 530px;
        }
        @include small {
          height: 380px;
        }
        @include onlytablet {
          height: 460px;
        }
      }
      @include middle {
        height: 530px;
      }
      @include tablet {
        width: 55%;
      }
      @include small {
        height: 380px;
      }
      @include phone {
        width: 100%;
      }
      @include onlytablet {
        height: 460px;
      }
    }

    &-title {
      font-size: 64px;
      line-height: 77px;
      white-space: nowrap;
      text-align: right;
      direction: rtl;
      width: 0;
      position: relative;
      z-index: 1;
      align-self: flex-end;
      user-select: none;
      margin-bottom: 30px;

      @include middle {
        font-size: 36px;
        line-height: 43px;
      }
      @media (min-width: $small + 1) and (max-width: $middle) {
        right: 95px;
        font-size: 52px;
        line-height: 62px;
      }
      @include phone {
        font-size: 36px;
        line-height: 43px;
        text-align: center;
        width: 100%;
        position: absolute;
        top: 0;
        color: black;
        margin-top: -180px !important;
      }
    }
    &-name {
      font-size: 48px;
      line-height: 58px;
      height: 100px;
      display: flex;
      align-items: flex-end;
      @include middle {
        font-size: 30px;
        line-height: 37px;
        height: 1em;
      }
      @include phone {
        height: auto;
        font-size: 30px;
        line-height: 37px;
        margin-top: 10px;
      }
    }
    &-location {
      font-weight: 300;
      font-size: 30px;
      line-height: 37px;
      @include middle {
        font-size: 24px;
        line-height: 29px;
      }
      @include phone {
        font-weight: 300;
        font-size: 20px;
        line-height: 24px;
      }
    }
    &-price {
      font-weight: 600;
      font-size: 20px;
      @include middle {
        font-size: 18px;
        line-height: 22px;
      }
      @include phone {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
      }
    }
    &-params {
      font-size: 20px;
      display: flex;
      @include gap(1em, row);
      // min-width: max-content;
      white-space: nowrap;
      width: 0;
      @include middle {
        font-size: 18px;
        line-height: 22px;
      }
      @include tablet {
        font-size: 16px;
        line-height: 19px;
      }
    }
    &-param {
      // display: inline-block;
    }
    .button {
      display: flex;

      align-self: flex-start;
      padding-left: 40px;
      padding-right: 40px;
      margin-top: 35px;
      align-items: center;
      justify-content: center;

      @include middle {
        margin-top: 20px;
        font-size: 18px;
        line-height: 1em;

        // line-height: 60px;
      }
      @include phone {
        // align-self: stretch;
        font-size: 24px;
        width: 100%;
        margin-top: 16px;
      }
    }
    &-subimage {
      width: 100%;
      margin-bottom: -60px;
      margin-top: 90px !important;
      height: 250px;
      object-fit: cover;
      @include middle {
        height: 200px;
      }
      @include onlytablet {
        margin-top: 20px !important;
        height: 170px;
        max-height: 170px;
      }
      @include tablet {
        height: 170px;
      }
    }
    .slider-title ~ .slider-subimage {
      margin-top: 30px;
      @include middle {
        height: 170px;
      }
    }

    &._sold {
      .slider-title {
        @include middle {
          margin-top: -50px;
        }
        @include phone {
          margin-top: -220px !important;
        }
      }
      .slider-arrow {
        display: block;
      }
      .slider-image {
        @include tablet {
          margin-top: -60px;
        }

        @media (min-width: $tablet + 1) and (max-width: $middle) {
          height: 560px;
        }

        @include onlytablet {
          height: 460px;
          margin-top: -150px;
        }
      }
      .slider-image img {
        margin-top: 0px;
        height: 650px;
        object-fit: cover;
        @include middle {
          height: 530px;
        }
        @include phone {
          height: 360px;
          margin-top: -160px;
        }
        @include onlytablet {
          height: 460px;
        }
      }
      .slider-cont {
        padding-top: 120px;
        padding-bottom: 150px;
        @include nophone {
          overflow: hidden;
        }
        @include middle {
          padding-top: 100px;
          padding-bottom: 120px;
        }
        @include onlytablet {
          margin-top: 80px;
        }

        @include phone {
          padding-top: 0;
          margin-top: 160px;
          padding-bottom: 40px;
        }
      }
      .slider-bg {
        @include onlytablet {
          margin-top: 80px;
        }
        @include phone {
          margin-top: 140px;
          height: auto;
        }
      }
      .slider-text {
        padding-top: 0px;
      }
      .slider-pagination {
        color: white;
        top: auto;
        right: auto;
        bottom: -50px;
        left: 50%;
        transform: translateX(-50%);
        @include phone {
          color: black;
          top: -40px;
          bottom: auto;
        }
      }
      @include phone {
        .slider-subimage {
          display: none;
        }
      }

      .slider-action:active {
        color: white;
      }
    }

    &._first {
      .slider-cont {
        @include phone {
          margin-top: 260px;
        }
      }
      .slider-bg {
        @include phone {
          margin-top: 260px;
        }
      }
    }

    &._reverse {
      .slider-cont {
        color: black;
        flex-direction: row-reverse;
        margin-left: 0px;
        @include phone {
          padding-bottom: 0;
          flex-direction: column;
        }
      }
      .slider-text {
        margin-right: 50px;
        margin-left: 0px;
        @include middle {
          margin-right: 20px;
        }
        @include onlytablet {
          margin-right: 50px;
        }
      }
      .slider-bg {
        background: white;
      }
      .slider-pagination {
        color: black;
      }

      .slider-action:active {
        color: black;
      }
    }
  }

  // .homePage {
  //   .slider-image{
  //     @include phone {
  //       height: 330px;

  //     }
  //   }
  // }
</style>
